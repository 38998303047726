import { Component } from "react";

const subtitle = "who we are";
const title = "we are future developer";
const desc =
  "at OppoSide, we stand at the forefront of the virtual reality revolution, crafting global VR solutions that transcend boundaries. our expansive portfolio is a testament to our commitment to innovation, encompassing a wide array of applications from immersive educational programs to complex industrial simulations. with a robust and skilled team of engineers at our core, we harness their collective expertise to push the envelope of what's possible. our strength lies in our diversity and the collaborative spirit that propels us forward. we believe in the power of VR to reshape training, education, and operational efficiency, providing our clients with experiences that are not only cutting-edge but also scalable and adaptable to their unique needs. our mission is to unlock the potential of virtual reality in every corner of the industry, creating impactful solutions that are both pioneering and pragmatic. we're not just building simulations; we're crafting experiences that educate, train, and prepare individuals and teams for the challenges of tomorrow. join us on this journey as we continue to expand the horizons of virtual reality, one innovative solution at a time.";
let AboutListContent = [
  {
    imgUrl: "assets/images/about/icon-1.png",
    imgAlt: "About Icon",
    title: "Innovative VR Solutions",
    desc: "We are dedicated to developing innovative VR solutions that transform the way industries approach training, education, and operational efficiency.",
  },
  {
    imgUrl: "assets/images/about/icon-2.png",
    imgAlt: "About Icon",
    title: "Empowering Education",
    desc: "Our immersive VR educational programs empower students and professionals, providing hands-on experience and enhancing learning outcomes through realistic simulations.",
  },
  {
    imgUrl: "assets/images/about/icon-3.png",
    imgAlt: "About Icon",
    title: "Driving Industrial Excellence",
    desc: "We deliver complex industrial simulations that improve operational efficiency, reduce costs, and ensure safety, driving excellence in various sectors.",
  },
];

class AboutSection extends Component {
  render() {
    const { imgUrl , videoUrl } = this.props;
    return (
      <section className="about-section">
        <div className="container">
          <div className="section-wrapper padding-top">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-image">
                  <img src={imgUrl} alt="about-image" />
                  {/* <video autoPlay muted loop width={"500"} height={"350"}>
                    <source src={this.props.videoUrl} type="video/mp4" />
                  </video> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="about-wrapper">
                  <div className="section-header">
                    <p>{subtitle}</p>
                    <h2>{title}</h2>
                  </div>
                  <div className="about-content">
                    <p>{desc}</p>
                    <ul className="about-list">
                      {AboutListContent.map((val, i) => (
                        <li className="about-item d-flex flex-wrap" key={i}>
                          <div className="about-item-thumb">
                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          </div>
                          <div className="about-item-content">
                            <h5>{val.title}</h5>
                            <p>{val.desc}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutSection;

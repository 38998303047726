import { Component } from "react";
import { Link } from "react-router-dom";

const title = "Our Activity Areas";
const btnText = "Browse All Activity Areas";

let CollectionListContent = [
  {
    imgUrl: "assets/images/mini/industrial.jpg",
    imgAlt: "VR Industial",
    title: "VR Industial",
  },
  {
    imgUrl: "assets/images/mini/military.jpg",
    imgAlt: "VR Army",
    title: "VR Army",
  },
  {
    imgUrl: "assets/images/mini/education.jpg",
    imgAlt: "VR Education",
    title: "VR Education",
  },
];

class CollectionSection extends Component {
  render() {
    return (
      <section className="collection-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header">
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row g-4 justify-content-center CollectionStyle">
              {CollectionListContent.map((val, i) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
                  <div className="game-item item-layer">
                    <div className="game-item-inner">
                      <div className="game-thumb">
                        {/* <video loop muted autoPlay style={{ width: "100%" }}>
                          <source src={val.videoUrl} />
                        </video> */}
                        <img src={val.imgUrl} alt={val.imgAlt} />
                      </div>
                      <div className="game-overlay">
                        <h4>
                          <Link to="/team-single">{val.title}</Link>{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="button-wrapper text-center mt-5">
              <Link to="/game-list" className="default-button">
                <span>
                  {btnText} <i className="icofont-circled-right"></i>
                </span>{" "}
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default CollectionSection;

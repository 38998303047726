import { Component } from "react";
import { Link } from "react-router-dom";

const title = "TODAY BIG MATCHE";
const desc = "Time : 08:30Pm   -  30 December 2021";
const btnText = "Watch Now";

class BannerSection extends Component {
  render() {
    return (
      <video
        src="assets/video/collection/01.mp4"
        autoPlay
        loop
        muted
        className="w-100"
      />
    );
  }
}

export default BannerSection;

import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import DemoCaskEditor from "../component/cask-editor/democaskeditor.jsx";
import PageHeader from "../component/layout/pageheader";

class ExploreCaskEditor extends Component {
  render() {
    return (
    //   <Fragment>
    //   <Header />
    //   <PageHeader title={"CASK EDITOR"} curPage={"CASK EDITOR DEMO"} />
    //   <DemoCaskEditor />
    //   <Footer />
    // </Fragment>
    <Fragment>
      <Header />
      <PageHeader title={"CASK EDITOR"} curPage={"CASK EDITOR DEMO"} />
      <h1>Coming Soon</h1>
      <Footer />
    </Fragment>
    );
  }
}

export default ExploreCaskEditor;

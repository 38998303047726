import React from 'react';

const features = [
  {
    icon: 'assets/images/about/icon-1.png',
    title: 'Realistic Simulations',
    description: 'Detailed simulations of industrial and military equipment offer practical, hands-on experience.',
  },
  {
    icon: 'assets/images/about/icon-2.png',
    title: 'Global Collaboration',
    description: 'Users can collaborate in real-time from anywhere, enhancing problem-solving and innovation.',
  },
  {
    icon: 'assets/images/about/icon-3.png',
    title: 'Customizable Content',
    description: 'Simulations can be tailored to specific industry needs, making the platform versatile for various educational applications.',
  },
];

const VRSection = ({ videoUrl }) => (
  <section style={{
    position: 'relative',
    overflow: 'hidden',
    color: 'white',
    textAlign: 'center',
    padding: '100px 20px'
  }}>
    <video autoPlay muted loop style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: -1
    }}>
      <source src={videoUrl} type="video/mp4" />
    </video>
    <div style={{
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: '20px'
      }}>We Are Future Developers</h1>
      <p style={{
        maxWidth: '800px',
        marginBottom: '40px'
      }}>Virtual Reality (VR) is revolutionizing education, providing immersive and interactive learning experiences that bridge the gap between theory and practice.</p>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}>
        {features.map((feature, index) => (
          <div key={index} style={{
            width: '250px',
            margin: '15px',
            padding: '20px',
            background: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '10px'
          }}>
            <img src={feature.icon} alt="feature icon" style={{
              width: '80px',
              height: 'auto',
              marginBottom: '10px'
            }} />
            <h5 style={{
              fontSize: '1.2rem',
              marginBottom: '5px'
            }}>{feature.title}</h5>
            <p style={{
              fontSize: '0.9rem'
            }}>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default VRSection;

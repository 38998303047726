import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer.jsx";
import Header from "../component/layout/header.jsx";
import PageHeader from "../component/layout/pageheader.jsx";
import VREdu from "../component/section/vreducation.jsx";
class ExploreCaskEditor extends Component {
  render() {
    return (

    <Fragment>
      <Header />
      <PageHeader title={"Vr Education"} curPage={"VR Education"} />
      <VREdu videoUrl={"assets/video/stock-videos/15.mp4"} />
      <Footer />
    </Fragment>
    );
  }
}

export default ExploreCaskEditor;

import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import SocialMedia from "../component/section/socialmedia";

const subtitle = "EMPOWERING THE GLOBE THROUGH INNOVATIVE VR SOLUTIONS";
const title = "WHO WE ARE";
const desc =
  "At OppoSide, we stand at the forefront of the virtual reality revolution, crafting global VR solutions that transcend boundaries. Our expansive portfolio is a testament to our commitment to innovation, encompassing a wide array of applications from immersive educational programs to complex industrial simulations.With a robust and skilled team of engineers at our core, we harness their collective expertise to push the envelope of what's possible. Our strength lies in our diversity and the collaborative spirit that propels us forward. We believe in the power of VR to reshape training, education, and operational efficiency, providing our clients with experiences that are not only cutting-edge but also scalable and adaptable to their unique needs.Our mission is to unlock the potential of virtual reality in every corner of the industry, creating impactful solutions that are both pioneering and pragmatic. We're not just building simulations; we're crafting experiences that educate, train, and prepare individuals and teams for the challenges of tomorrow.Join us on this journey as we continue to expand the horizons of virtual reality, one innovative solution at a time.";
const playerTitle = "TEAM Members";

let WinPriceList = [
  {
    icon: "icofont-people",
    title: "20+ HAPPY CUSTOMERS",
  },
  {
    icon: "icofont-gears",
    title: "15+ ACTIVE PROJECTS",
  },
  {
    icon: "icofont-engineer",
    title: "TALENTED TEAM MEMBERS",
  },
  //   {
  //     icon: "icofont-workers-group",
  //     title: "02 PLAYS",
  //   },
];

let PlayerList = [
  {
    imgUrl: "assets/images/player/01.jpg",
    imgAlt: "Player Thumb",
    pName: "Guadalupe R. Johnson",
  },
  {
    imgUrl: "assets/images/player/02.jpg",
    imgAlt: "Player Thumb",
    pName: "AMADO R. MARTIN",
  },
  {
    imgUrl: "assets/images/player/03.jpg",
    imgAlt: "Player Thumb",
    pName: "KYLE S. STELLY",
  },
];

class TeamSinglePage extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <PageHeader title="About Us" curPage="About Us" />
        <div className="about-team padding-top padding-bottom">
          <div className="container">
            <div className="section-header">
              <h2 className="mb-3">{title}</h2>
              <p>{subtitle}</p>
              <p className="desc">{desc}</p>
            </div>
            <ul className="d-flex flex-wrap justify-content-center player-meta mb-0">
              {WinPriceList.map((val, i) => (
                <li className="d-flex align-items-center" key={i}>
                  <span className="left me-3">
                    <i className={val.icon}></i>
                  </span>
                  <span className="right">{val.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="player-section padding-top padding-bottom bg-attachment"
          style={{ backgroundImage: "url(/assets/images/video/bg3.jpg)" }}
        >
          <div className="container">
            <div className="section-header">
              <h2>{playerTitle}</h2>
            </div>
            <div className="section-wrapper">
              <div className="row g-4 justify-content-center">
                {PlayerList.map((val, i) => (
                  <div className="col-lg-4 col-sm-6 col-12" key={i}>
                    <div className="player-item">
                      <div className="player-inner">
                        <div className="player-thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div>
                        <div className="player-content text-center">
                          <div className="player-info-list">
                            <h3 className="mb-0">{val.pName}</h3>
                          </div>
                          <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center w-100">
                            {/* <SocialMedia /> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default TeamSinglePage;
